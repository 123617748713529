<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc1_0.jpg'),
        //   title: '工程造价',
        //   intro: '用真心换诚信 优质服务请放心'
        // },
      ],
      info: {
        title: '工程造价',
        desc: '提供建筑工程造价方面的咨询服务，包括编制和审核概算、预算和结算，制定投资控制计划，见证工程现场签证，审核计量支付款项，提出设计方案和施工方案的优化建议，参与合同评审并提出建议，及时反映设计变更和现场签证对造价的影响，进行全过程投资控制和提供全过程造价咨询服务。',
        list: [
          { id: 1, title: '确定工程范<br>围和图纸' },
          { id: 2, title: '计算工程量' },
          { id: 3, title: '确定材料和<br>设备价格' },
          { id: 4, title: '编制工程<br>预算' },
          { id: 5, title: '出具咨询<br>报告' },
          { id: 6, title: '实施咨询' },
          { id: 7, title: '签订合同' },
          { id: 8, title: '审核预算' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc1_1.jpg'),
          tit: '节约投资成本',
          desc: '我们通过精准的项目分析和市场调研制定费用预算，以避免成本超支并确保资金合理分配。同时实施严格的成本控制，从预算制定到实际支出，以节约投资成本并提高项目整体效益。'
        }, {
          id: 2,
          img: require('@/assets/images/svc1_2.jpg'),
          tit: '确保项目进度',
          desc: '全过程工程造价咨询有助于更精确地管理项目进度，通过比对实际进度和计划进度，及时发现和分析偏差，采取措施确保项目按时完成。同时，通过统一平台和机制，更好地整合资源，实现信息共享和协同工作，提升项目整体效率和品质。'
        }, {
          id: 3,
          img: require('@/assets/images/svc1_3.jpg'),
          tit: '确保服务质量',
          desc: '全过程工程造价咨询实现高效可靠的项目管理。整合各专业工程，形成完整项目链，确保顺畅沟通和协作。可避免信息传递错误或遗漏，大幅缩短项目周期，提高工作效率。'
        }, {
          id: 4,
          img: require('@/assets/images/svc1_4.jpg'),
          tit: '有效规避风险',
          desc: '平台定期培训相关人员，提升专业素质和责任心，确保科学正确决策。同时建立有效沟通和控制机制，保障信息准确及时传递，实现成本、时间和风险的有效管理。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>